<template>
  <v-container class="pb-0" grid-list-md v-if="masterData">
    <v-layout>
      <v-flex md6 v-if="selectedPayment" xs12>
        <notes
          :id="selectedPayment.id"
          :notes="selectedPayment.notes"
          type="paymentDataNotes"
        />
      </v-flex>
      <v-flex md6 xs12>
        <notes
          :id="masterData.id"
          :notes="masterData.notes"
          type="masterDataNotes"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Notes from '@/components/notes/Notes'

export default {
  name: 'NotesBlock',
  components: { Notes },
  props: {
    masterData: {
      required: true,
    },
    paymentYear: {
      type: String,
    },
  },
  computed: {
    selectedPayment() {
      return this.masterData.payments.find((el) => el.year === this.paymentYear)
    },
  },
}
</script>
