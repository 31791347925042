<template>
  <app-layout :append-content-container="false">
    <v-layout row fill-height>
      <pdf-viewer v-if="extractionData" :extraction-data="extractionData" />
      <v-flex xs12 :md12="!extractionData" :md6="extractionData">
        <v-btn v-if="extractionData" @click="extractionData = null">
          <v-icon>arrow_left</v-icon>
          PDF schließen
        </v-btn>
        <v-container fluid>
          <v-layout align-start column justify-start>
            <v-container class="pb-0" grid-list-md>
              <v-layout>
                <master-data-summary
                  v-bind="{ masterData }"
                  v-if="masterData"
                />
              </v-layout>
            </v-container>
            <v-container class="pb-0" grid-list-md>
              <v-layout>
                <v-flex md3>
                  <PaymentYearPicker
                    :master-data="masterData"
                    :value="year"
                    @input="changeYear"
                  />
                </v-flex>
                <v-flex md3 v-if="paymentSummary.length">
                  <modal-payment-summary
                    v-model="dialogs.paymentSummary"
                    :payment-summary="paymentSummary"
                    :year="year"
                  />
                  <v-btn @click="dialogs.paymentSummary = true"
                    >Zahlungen Ortsteile</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
            <payment-data-view-content
              v-if="masterData"
              :year="year"
              :master-data="masterData"
              :assign-document-mode="false"
              :document-data="documentData"
              :payment-summary="paymentSummary"
              @showDocument="showDocument"
              @savePayment="savePayment"
            />
            <v-alert v-if="errorFetching" :value="errorFetching">
              Der angeforderte Datensatz konnte nicht gefunden werden.
            </v-alert>

            <NotesBlock :master-data="masterData" :payment-year="year" />
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import masterDataApi from '@/api/masterdataApi'
import paymentApi from '@/api/paymentdataApi'
import documentsApi from '@/api/documentsApi'
import PaymentDataViewContent from '@/pages/paymentDataView/PaymentDataViewContent'
import PdfViewer from '@/components/pdf/PDFViewer'
import { hasExtractionDocument } from '@/mixins/hasExtractionDocument'
import MasterDataSummary from '@/components/MasterDataSummary'
import PaymentYearPicker from '@/components/PaymentYearPicker'
import ModalPaymentSummary from '@/pages/paymentDataView/ModalPaymentSummary'
import NotesBlock from '@/components/NotesBlock'

export default {
  name: 'PaymentDataView',
  components: {
    NotesBlock,
    ModalPaymentSummary,
    PaymentYearPicker,
    MasterDataSummary,
    PdfViewer,
    PaymentDataViewContent,
    AppLayout,
  },
  props: ['id', 'year'],
  mixins: [hasExtractionDocument],
  provide() {
    return {
      documentDate: null,
    }
  },
  data() {
    return {
      masterData: null,
      extractionData: null,
      paymentSummary: [],
      errorFetching: false,
      dialogs: {
        paymentSummary: false,
      },
    }
  },
  methods: {
    async fetchData(id) {
      try {
        this.masterData = await masterDataApi.getEntry(id)
        await this.$store.dispatch(
          'setCurrentCompany',
          this.masterData.company_id
        )
        this.paymentSummary = await masterDataApi.getChildrenPaymentSummary(
          id,
          this.year
        )
      } catch (e) {
        this.errorFetching = true
      }
    },

    savePayment(payment) {
      paymentApi.updateEntry(payment)
    },
    changeYear(year) {
      this.$router.push({
        name: 'PaymentDataView',
        params: { id: this.id, year },
      })
    },
    async showDocument(document_id) {
      const isLoaded =
        this.extractionData && this.extractionData.id === document_id
      if (!isLoaded) {
        try {
          this.extractionData = await documentsApi.getEntry(document_id)
        } catch (e) {
          this.$root.setSnackbar(
            'error',
            'Dokument konnte nicht geöffnet werden'
          )
        }
      }
    },
  },
  watch: {
    id: {
      handler(id) {
        this.errorFetching = false
        this.dialogs.paymentSummary = false
        this.paymentSummary = []
        this.fetchData(id)
      },
      immediate: true,
    },
  },
}
</script>

<style scoped></style>
